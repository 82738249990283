import { IRoseAGGridColumn } from '@rose/common-ui';
import { ITerminart } from '@rose/types';
import { IDeleteButtonCell } from '@/components/cells/cellInterfaces';
import { editSettingsStore } from '@/state/settings/editSettingsStore';

export function settingsAbrechnungTerminArtenColumnsDefs(): IRoseAGGridColumn<ITerminart>[] {
  return [
    {
      headerName: 'Terminart',
      field: 'bezeichnung',
      valueFormatter: params => `${params.data?.bezeichnung} (${params.data?.extid})`,
      sortable: true,
      sort: 'asc',
      flex: 1,
    },
    {
      headerName: '',
      field: 'actions',
      cellRenderer: 'DeleteButtonCell',
      width: 40,
      cellStyle: { textAlign: 'center', 'justify-content': 'center' },
      cellRendererParams: {
        context: {
          clicked(field: { row: ITerminart }) {
            editSettingsStore.commit.deleteIgnorierteTerminart(field.row.extid);
          },
        },
      } as IDeleteButtonCell<ITerminart>,
    },
    {
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 40,
    },
  ];
}
