import { IRoseAGGridColumn, IAbrechnungsVorschlaegeRegelnUI } from '@rose/common-ui';

export function settingsAbrechnungColumnsDefs(): IRoseAGGridColumn<IAbrechnungsVorschlaegeRegelnUI>[] {
  return [
    {
      headerName: 'Regel',
      field: 'kurzName',
      sortable: true,
      sort: 'asc',
      width: 350,
    },
    {
      headerName: 'Hilfe',
      field: 'beschreibung',
      width: 80,
      // cellClass: 'ag-cell-stichworte',
      valueFormatter: params => params.data?.beschreibung || params.data?.beschreibungVorschlag || '',
      cellRenderer: 'HelpTooltipCell',
    },
    {
      headerName: 'Art',
      field: 'abrechnungsart',
      valueFormatter: params => (params.data?.abrechnungsart === 0 ? 'GOZ' : 'BEMA'),
      width: 80,
      sortable: true,
    },
    {
      headerName: 'Vorschlagsart',
      field: 'vorschlagsart',
      valueFormatter: params => (params.data?.typ === 'ABRECHNUNG' ? 'Abrechnung' : 'Behandlung'),
      resizable: true,
      width: 110,
    },
    {
      headerName: 'Aktiv',
      width: 80,
      cellStyle: { textAlign: 'center', 'justify-content': 'center' },
      cellRenderer: 'AARegelActiveCell',
    },
    {
      headerName: 'Überweiser',
      headerTooltip: 'Regel bei Überweisern ignorieren',
      width: 120,
      cellStyle: { textAlign: 'center', 'justify-content': 'center' },
      cellRenderer: 'AAIgnoreUeberweiserCell',
    },
    {
      headerName: 'Regel bei diesen Stichworten ignorieren',
      field: 'typ',
      flex: 1,
      cellClass: 'ag-cell-stichworte',
      cellRenderer: 'StichworteCell',
    },
    {
      headerName: 'Weitere Einstellungen',
      field: 'weitere',
      width: 320,
      cellRenderer: 'EinstellungenCell',
    },
  ];
}
