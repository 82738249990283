import "core-js/modules/es.array.map.js";
import { defineComponent } from 'vue';
import DeleteButtonCell from '@/components/cells/DeleteButtonCell.vue';
import { german } from '@rose/common-ui';
export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true
    },
    itemsAvailable: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      rowClass: 'row-class-even',
      itemsAdded: [],
      itemsSelected: [],
      components: {
        actionButtonsRenderer: DeleteButtonCell
      },
      showDeleteAllSelected: false
    };
  },
  created: function created() {
    this.gridOptions = {
      rowHeight: 43,
      defaultColDef: {
        resizable: true,
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      suppressCellFocus: true,
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true
    };
  },
  methods: {
    getRowClass: function getRowClass(params) {
      if (params.node.rowIndex % 2 === 0) {
        return 'row-class-odd';
      }
    },
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('cell clicked:', event);
    },
    addItems: function addItems() {
      var items = this.itemsAdded.map(function (v) {
        return v.toLowerCase();
      });
      this.$emit('addItems', items);
      this.itemsAdded = [];
      this.showDeleteAllSelected = false;
    },
    deleteItems: function deleteItems() {
      var selected = this.getSelectedRows();
      this.$emit('deleteItems', selected);
      this.showDeleteAllSelected = false;
      this.gridApi.deselectAll();
    },
    getSelectedRows: function getSelectedRows() {
      return this.gridApi.getSelectedRows();
    },
    onSelectionChanged: function onSelectionChanged(params) {
      this.showDeleteAllSelected = !!this.getSelectedRows().length;
    }
  }
});