import { defineComponent } from 'vue';
import { german } from '@rose/common-ui';
import { settingsAbrechnungColumnsDefs } from '@/components/columns/settings/abrechnung';
import { BemaGozType } from '@rose/types';
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    leistungKey: {
      type: String,
      required: true
    },
    items: {
      type: [],
      required: true
    }
  },
  data: function data() {
    return {
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: null,
      dialog: false
    };
  },
  created: function created() {
    this.columnDefs = settingsAbrechnungColumnsDefs();
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        autoHeight: true
      },
      suppressCellFocus: true,
      suppressRowHoverHighlight: true
    };
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('cell clicked:', event);
    },
    getRowClass: function getRowClass(params) {
      var _a;

      if (((_a = params.data) === null || _a === void 0 ? void 0 : _a.abrechnungsart) === BemaGozType.GOZ) {
        return 'privat-row-test';
      } // if (params.node.rowIndex && params.node.rowIndex % 2 === 0) {
      //   return 'row-class-odd';
      // }

    },
    getRowId: function getRowId(params) {
      return params.data.id;
    }
  }
});